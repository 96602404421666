import { combineReducers } from 'redux';
import users from './users';
import liability from './liabilty';
import injuryData from './injuryData';
import damageData from './damageData';
import subscription from './subscription';
import PreProcessMedicalRecords from './preProccessedMedicalRecords';

const rootReducer = combineReducers({
  users: users,
  liability: liability,
  injuryData: injuryData,
  damageData: damageData,
  subscription: subscription,
  preProcessMedicalRecords: PreProcessMedicalRecords
});

export default rootReducer;