import { useState, useCallback, useEffect } from 'react';
import useAxios from "../../hooks/useAxios";
import Constants from "../../Constants";
import { toaster } from "../../utils/toast";
import { separateFilenameAndExtension } from '../../utils/helper';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { CASE_TYPE, CASE_TYPE_DEMANDS, CASE_TYPE_MAP, DEMAND, DEMAND_TYPE } from '../../utils/enum';
import { templateEditorDeafultFontFamily } from '../../utils/constant';
import { confirm } from '../../utils/swal';

const useTemplate = () => {
    const [templateData, setTemplateData] = useState({});
    const [fieldLoader, setFieldLoader] = useState({});
    const [state, setState] = useState('California');
    const { postData } = useAxios();
    const { getData, isLoading: getTemplateIsLoading } = useAxios();
    const { getData: getSettingData, isLoading: getSettingDataIsLoading } = useAxios();
    const [demandType, setDemandType] = useState(DEMAND.TP_PLD);
    const [caseType, setCaseType] = useState(CASE_TYPE.AUTO_ACCIDENT);
    const [fontFamily, setFontFamily] = useState(templateEditorDeafultFontFamily)

    const removeFontFamilyStyle = (html) => {
        return html?.replace(/font-family:[^;"}]*;?/gi, '');
    }

    const getTemplate = useCallback(async () => {
        let { success, data, message } = await getData(`${Constants.ApiUrl.template.getDemandTemplate}/${demandType}/${caseType}/${state}`);
        if (success) {
            data = JSON.parse(removeFontFamilyStyle(JSON.stringify(data)))
            setTemplateData(prevData => ({
                // Preserve settings fields
                firmName: prevData.firmName,
                firmAddress: prevData.firmAddress,
                attorneyName: prevData.attorneyName,
                attorneyEmail: prevData.attorneyEmail,
                companyLogo: prevData.companyLogo,
                billedAmountHeading: prevData.billedAmountHeading,
                fontFamily: prevData.fontFamily,
                // Add template data
                ...data
            }));
            if (data?.fontFamily) {
                setFontFamily(data?.fontFamily)
            }
        } else {
            setTemplateData(prevData => ({
                // Preserve settings fields
                firmName: prevData.firmName,
                firmAddress: prevData.firmAddress,
                attorneyName: prevData.attorneyName,
                attorneyEmail: prevData.attorneyEmail,
                companyLogo: prevData.companyLogo,
            }));
            toaster({ message, success });
        }
    }, [getData, state, demandType, caseType]);

    const getTemplateSettings = useCallback(async () => {
        let { success, data, message } = await getSettingData(`${Constants.ApiUrl.template.getTemplateSettings}`);
        if (success) {
            data = JSON.parse(removeFontFamilyStyle(JSON.stringify(data)))
            setTemplateData((prevData) => ({
                ...prevData,
                ...data,
            }));
            if (data?.fontFamily) {
                setFontFamily(data?.fontFamily)
            }
        } else {
            toaster({ message, success });
        }
    }, [getSettingData]);

    useEffect(() => {
        getTemplateSettings();
    }, [getTemplateSettings]);

    useEffect(() => {
        getTemplate();
    }, [getTemplate]);

    const addUpdateTemplate = async (payload, companyLogoFile) => {
        payload = { ...payload, state, demandType, caseType }
        const { success, data, message } = await postData(Constants.ApiUrl.template.addUpdateDemandTemplate, payload);
        if (success) {
            setTemplateData((prevData) => ({
                ...prevData,
                ...data,
            }));
        }
        toaster({ message, success });
    }

    const addUpdateCommonTemplate = async (payload, companyLogoFile) => {
        payload = { ...payload }
        const { success, data: { updatedTemplateSettings, putCompanyLogoSignedUrl }, message } = await postData(Constants.ApiUrl.template.addUpdateTemplateSettings, payload);
        if (success) {
            if (companyLogoFile && putCompanyLogoSignedUrl) {
                const uploadFileRes = await axios.put(putCompanyLogoSignedUrl, companyLogoFile, {
                    headers: {
                        'Content-Type': companyLogoFile.type,
                    },
                });

                console.log(uploadFileRes);
                setTemplateData((prevData) => ({
                    ...prevData,
                    ...updatedTemplateSettings,
                    companyLogo: URL.createObjectURL(companyLogoFile)
                }));
            } else {
                setTemplateData((prevData) => ({
                    ...prevData,
                    ...updatedTemplateSettings,
                }));
            }
        }
        toaster({ message, success });
    }

    const onFileSelected = async (e) => {
        const file = e.target.files[0]
        const { ext } = separateFilenameAndExtension(file.name);
        if (["pdf", "xlsx", "docx", "zip", "txt"].includes(ext)) {
            alert('Un-Supported files');
        } else {
            const payload = {
                companyLogo: {
                    name: file.name,
                    mimetype: file.type
                }
            }
            addUpdateCommonTemplate(payload, file);
        }
    };

    const onChangeState = (e) => {
        e.preventDefault();
        setState(e.target.value);
    }

    const onChangeDemandType = (e) => {
        e.preventDefault();
        setDemandType(e.target.value);
    }

    const onChangeCaseType = (e) => {
        e.preventDefault();
        const value = e.target.value
        if (CASE_TYPE_DEMANDS[value].includes(demandType)) {
            setCaseType(e.target.value);
        }
        else {
            confirm({ icon: "error", title: "Invalid Selection...", text: `${DEMAND_TYPE[demandType].text} not support ${CASE_TYPE_MAP[value].text} case type` })
        }

    }

    const onChangeFontFamily = (e) => {
        e.preventDefault();
        const value = e.target.value
        setFontFamily(value);
        const payload = { fontFamily: value }
        addUpdateCommonTemplate(payload)
    }

    const handelTemplateDataChange = (updatedTemplate) => {
        setTemplateData((prevData) => ({
            ...prevData,
            ...updatedTemplate
        }));
    }

    const onBlurHandler = async ({ field, value, isCommon }) => {
        const payload = { [field]: value }
        toggelFieldLoader(field)
        if (isCommon) {
            await addUpdateCommonTemplate(payload);

        } else {
            await addUpdateTemplate(payload);
        }
        toggelFieldLoader(field)
    }

    const toggelFieldLoader = (field) => {
        setFieldLoader((prevState) => ({ ...prevState, [field]: !prevState?.[field] }))
    }

    const sanitizeHtml = (html) => DOMPurify.sanitize(html);

    const removeCompanyLogo = () => {
        const payload = { "companyLogo": "removeLogo" }
        addUpdateCommonTemplate(payload)
    }

    const onChangeAmoutText = (e) => {
        e.preventDefault();
        const value = e.target.checked
        const payload = { 'billedAmountHeading': value }
        addUpdateCommonTemplate(payload)
    }

    return {
        templateData,
        state,
        onFileSelected,
        onChangeState,
        onBlurHandler,
        sanitizeHtml,
        getTemplateIsLoading,
        handelTemplateDataChange,
        fieldLoader,
        onChangeFontFamily,
        fontFamily,
        onChangeAmoutText,
        removeCompanyLogo,
        demandType,
        caseType,
        onChangeCaseType,
        onChangeDemandType,
        getSettingDataIsLoading
    };
};

export default useTemplate;