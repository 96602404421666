export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const STORED_LIABILTY = 'STORED_LIABILTY';
export const STORED_INJURY_ANALYSIS = 'STORED_INJURY_ANALYSIS';
export const STORED_DAMAGE_ANALYSIS = 'STORED_DAMAGE_ANALYSIS';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const PRE_PROCCESSED_MEDICAL_DATA = 'PRE_PROCCESSED_MEDICAL_DATA';
