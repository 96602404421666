import React, { useState, useEffect } from 'react';
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { TextInput, DatePicker, Dropdown, FileUpload, TextArea, RenderIf } from '../../../../components';
import Constants from '../../../../Constants';
import { storeLiablityEstern } from '../../../../redux/actions/liabilty';
import { useSelector, useDispatch } from 'react-redux';
import XSelect from '../../../../components/common/XSelect';
import { CASE_TYPE, CASE_TYPE_MAP } from '../../../../utils/enum';
import XMultiImageUpload from './XMultiImageUpload';
import useAxios from '../../../../hooks/useAxios';


const LiabilityAnalysisForm = ({ onSubmit, onPoliceReportUpload }) => {
  const dispatch = useDispatch();
  const liability = useSelector(state => state.liability.liability);
  const [caseName, setcaseName] = useState('');
  const [description, setdescription] = useState('');
  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [ssn, setSsn] = useState('');
  const [faulterName, setFaulterName] = useState('');
  const [policeReportUpload, setPoliceReportUpload] = useState([]);
  const [fileName, setFileName] = useState('');
  const [accidentSceneFiles, setAccidentSceneFiles] = useState([]);

  const [formValues, setFormValues] = useState({})
  const [shouldRenderIncindentDescription, setShouldRenderIncidentDescription] = useState(false);
  const [bodilyInjuriesImageFiles, setBodilyInjuriesImageFiles] = useState([]);
  const [incidentReportImageFiles, setIncidentReportImageFiles] = useState([]);
  const [productImageFiles, setProductImageFiles] = useState([]);
  const [values, setValues] = useState({})
  const { postData } = useAxios()

  const viewFileInNewTab = async (file) => {
    if (file instanceof File || file instanceof Blob) {
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, '_blank');
    }
    if (file.s3UrlPath) {
      const response = await postData(Constants.ApiUrl.utils.getSignedUrl, {
        path: file.s3UrlPath
      })
      const signedUrl = response?.data?.signedUrl
      signedUrl && window.open(response?.data?.signedUrl, '_blank');
    }
  }


  useEffect(() => {

    if (liability) {
      setFormValues(liability);
      setcaseName(liability?.caseName);
      setDate(liability?.date);
      setName(liability?.name);
      setSsn(liability?.ssn);
      setFaulterName(liability?.faulterName)
      setAccidentSceneFiles(liability?.accidentPic)
      setFileName(liability?.policeReport?.name);

      if (!liability?.policeReport?.length) {
        if (liability?.description) {
          setShouldRenderIncidentDescription(true)
          setdescription(liability?.description)
        }
      } else {
        setPoliceReportUpload(liability?.policeReport)
        setShouldRenderIncidentDescription(false)
      }

      setBodilyInjuriesImageFiles(liability?.bodilyInjuriesImageFiles)
      setProductImageFiles(liability?.productImageFiles)
      setAccidentSceneFiles(liability?.accidentSceneFiles)
      setIncidentReportImageFiles(liability?.incidentReportImageFiles)

    }
  }, [liability]);

  const bodilyInjuriesFilesOnChange = (files) => {
    setBodilyInjuriesImageFiles(files)
  }

  const productImageFileOnChange = (files) => {
    setProductImageFiles(files)
  }

  const accidentSceneFilesOnChange = (files) => {
    setAccidentSceneFiles(files)
  }

  const incidentReportImageFilesOnChange = (files) => {
    setIncidentReportImageFiles(files)
  }

  const onFileSelected = async (files) => {
    if (files?.file?.length > 1) {
      setFileName('Multiple is selected');
    } else {
      setFileName(files?.file[0]?.name);
    }
    setPoliceReportUpload(files.file)
    setdescription("")
  }

  let formvalues = {};
  const validatoionSchema = Yup.object().shape({

    date: Yup.string().required('Date of Incident is required'),
    state: Yup.string().required('State of incident is required'),
    name: Yup.string().required('Name is required'),
    ssn: Yup.string().required('SSN is required'),
    description: shouldRenderIncindentDescription ? Yup.string().required('Description is required') : '',
    faulterName: Yup.string().required('At-Fault Party Name is Required')
  })

  return (
    <Formik
      initialValues={{
        caseName: caseName === "" ? values.caseName : caseName,
        unusedVariable: values ? values.caseType : 'Individual',
        caseType: formValues?.caseType ? formValues.caseType : CASE_TYPE.AUTO_ACCIDENT,
        date: date === "" ? values.date : date,
        state: liability?.state || 'California',
        name: name === "" ? values.name : name,
        description: description === "" ? "" : description,
        faulterName: faulterName === "" ? values.faulterName : faulterName,
        policeReport: values ? values.policeReport : policeReportUpload,
        incidentReportFile: formValues?.incidentReportFile ? formValues.incidentReportFile : [],
        witnessStatementFile: formValues?.witnessStatementFile ? formValues.witnessStatementFile : [],
        expertSafetyReport: formValues?.expertSafetyReport ? formValues.expertSafetyReport : [],
        accidentScenes: '',
        insuranceCompanyName: values ? values.insuranceCompanyName : '',
        ssn: ssn === "" ? values.ssn : ssn,
        incidentText: formValues?.incidentText,
        dangerousConditions: formValues?.dangerousConditions,
        stateFactsNotice: formValues?.stateFactsNotice,
        factsFailureClaim: formValues?.factsFailureClaim,
        factsManufacturingDefect: formValues?.factsManufacturingDefect,
        factsConsumerExpectation: formValues?.factsConsumerExpectation,
        factsRipkBenefit: formValues?.factsRipkBenefit,
      }}
      enableReinitialize={true}
      validationSchema={validatoionSchema}
      onSubmit={async (values) => {

        setFormValues(values)
        formvalues = { ...formvalues, ...values }
        const updatedFormValues = {
          ...formvalues,
          policeReport: values?.description !== "" ? [] : policeReportUpload,
          accidentSceneFiles,
          bodilyInjuriesImageFiles,
          incidentReportImageFiles,
          productImageFiles,
        };
        dispatch(storeLiablityEstern(updatedFormValues));
        setValues({ liability: values })
        onSubmit({
          liability: updatedFormValues,
          isDraftCase: false
        })
      }}
    >
      {(formik) => (
        < Form >
          <div className="add-form p-0">
            <div className="card">
              <h2 className="title">Liability Analysis
              </h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="caseName" placeholder="Enter Case Name" label="Case Name" component={TextInput} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="unusedVariable"
                      label="Individual/Multiple"
                      component={Dropdown}
                      defaultOption={'Individual'}
                      options={Constants.Dropdowns.CaseTypes}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="date" label="Date of Incident" component={DatePicker}
                    />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="state"
                      label="State Where Incident Occurred"
                      component={Dropdown}
                      defaultOption="California"
                      options={Constants.Dropdowns.States}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="name" placeholder="Enter Name" label="Name (Plaintiff Name)" component={TextInput} />
                  </div>
                  <div className="form-group">
                    <Field name="faulterName" placeholder="Enter Name" label="Name of At-Fault Party" component={TextInput} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field name="ssn" placeholder="Write SSN" label="SSN" component={TextInput} isSSN={true} />
                  </div>

                  <XSelect
                    emptyOption={false}
                    label="Case Type"
                    value={formik.values.caseType}
                    options={Object.values(CASE_TYPE_MAP)}
                    onChange={(e) => {
                      formik.setFieldValue('caseType', e.target.value);
                    }}
                  />
                </div>

                {/* ================================================Start================================================== */}
                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY, CASE_TYPE.DOG_BITE, CASE_TYPE.PRODUCT_LIABILITY].includes(formik.values.caseType)}>
                  {/* Incident Occurred Text */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field name="incidentText" placeholder="Write here..." label="Describe How The Incident Occurred" component={TextArea} height={20.6} />
                    </div>
                  </div>

                  {/* Incident Reports */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Attach Incident Reports (If Available)</label>
                      <div className="file-upload">
                        <div className="text text-center">
                          {formik?.values?.incidentReportFile?.length > 0 ? (<><i className='fa-solid fa-eye'
                            onClick={(e) => {
                              e.stopPropagation()
                              viewFileInNewTab(formik?.values?.incidentReportFile[0])
                            }}
                          ></i><i className='fa-solid fa-trash ms-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              formik.setFieldValue('incidentReportFile', [])
                            }}
                          ></i></>) : (<><Field name="incidentReportFile" component={FileUpload} multiplefileUpload={true} onFileSelected={(files) => {
                            formik.setFieldValue("incidentReportFile", files.file);
                          }} documentName='incidentReports' accept="application/pdf" />
                            <i className="fa-solid fa-upload"></i></>)}

                          <p>{formik.values?.incidentReportFile?.length >= 1 ? (formik.values?.incidentReportFile?.length === 1 ? formik.values?.incidentReportFile[0]?.name : 'Multiple Files Selected') : "Upload file type .pdf"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </RenderIf>

                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY].includes(formik.values.caseType)}>
                  {/* Dangerous Conditions Text */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field name="dangerousConditions" placeholder="Write here..." label="Describe The Dangerous Conditions" component={TextArea} height={20.6} />
                    </div>
                  </div>
                </RenderIf>

                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY, CASE_TYPE.DOG_BITE].includes(formik.values.caseType)}>
                  {/* Incident Report images */}
                  <div className="col-md-6">
                    <XMultiImageUpload
                      label="Attach photographs of Incident (If Available)"
                      name="incidentReportImageFiles"
                      onChange={incidentReportImageFilesOnChange}
                      initialFiles={incidentReportImageFiles}
                    />
                  </div>
                </RenderIf>



                <RenderIf shouldRender={formik.values.caseType === CASE_TYPE.PRODUCT_LIABILITY}>
                  <div className="row" style={{ display: 'flex' }}>
                    <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      {/* Left Column - All Text Inputs */}
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <Field
                            name="factsFailureClaim"
                            placeholder="Write here..."
                            label="Facts to Support Failure To Warn Claim"
                            component={TextArea}
                            height={20.6}
                          />
                        </div>
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <Field
                            name="factsManufacturingDefect"
                            placeholder="Write here..."
                            label="Facts to Support a Manufacturing Defect"
                            component={TextArea}
                            height={20.6}
                          />
                        </div>
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <Field
                            name="factsConsumerExpectation"
                            placeholder="Write here..."
                            label="Facts to Support Consumer Expectation Test"
                            component={TextArea}
                            height={20.6}
                          />
                        </div>
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <Field
                            name="factsRipkBenefit"
                            placeholder="Write here..."
                            label="Facts to Support RIPK-Benefit Test"
                            component={TextArea}
                            height={20.6}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      {/* Right Column - All File Uploads */}
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        {/* Product Photo File */}
                        <XMultiImageUpload
                          label="Attach Product Photo (If Available)"
                          name="productImageFiles"
                          onChange={productImageFileOnChange}
                          initialFiles={productImageFiles}
                        />

                        {/* Expert Safety Report */}
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <label className="form-label">Attach Expert Safety Report (If Available)</label>
                          <div className="file-upload">
                            <div className="text text-center" style={{ position: 'relative' }}>

                              {formik?.values?.expertSafetyReport?.length > 0 ? (<><i className='fa-solid fa-eye'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  viewFileInNewTab(formik?.values?.expertSafetyReport[0])
                                }}
                              ></i><i className='fa-solid fa-trash ms-2'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  formik.setFieldValue('expertSafetyReport', [])
                                }}
                              ></i></>) : (<><Field
                                name="expertSafetyReport"
                                component={FileUpload}
                                multiplefileUpload={true}
                                onFileSelected={(files) => {
                                  formik.setFieldValue("expertSafetyReport", files?.file);
                                }}
                                documentName='expertSafetyReport'
                                accept="application/pdf"
                              />
                                <i className="fa-solid fa-upload"></i></>)}

                              <p>{formik.values?.expertSafetyReport?.length >= 1 ? (formik.values?.expertSafetyReport?.length === 1 ? formik.values?.expertSafetyReport[0]?.name : 'Multiple Files Selected') : "Upload file type .pdf"}</p>
                            </div>
                          </div>
                        </div>

                        {/* Witness Statement */}
                        <div className="form-group" style={{ flex: 1, marginBottom: '20px' }}>
                          <label className="form-label">Attach Witness Statement (If Available)</label>
                          <div className="file-upload">
                            <div className="text text-center" style={{ position: 'relative' }}>
                              {formik?.values?.witnessStatementFile?.length > 0 ? (<><i className='fa-solid fa-eye'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  viewFileInNewTab(formik?.values?.witnessStatementFile[0])
                                }}
                              ></i><i className='fa-solid fa-trash ms-2'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  formik.setFieldValue('witnessStatementFile', [])
                                }}
                              ></i></>) : (<><Field name="witnessStatementFile" component={FileUpload} multiplefileUpload={true} onFileSelected={(files) => {
                                formik.setFieldValue("witnessStatementFile", files?.file);
                              }} documentName='witnessStatementFile' accept="application/pdf" /><i className="fa-solid fa-upload"></i></>)}
                              <p>{formik.values?.witnessStatementFile?.length >= 1 ? (formik.values?.witnessStatementFile?.length === 1 ? formik.values?.witnessStatementFile[0]?.name : 'Multiple Files Selected') : "Upload file type .pdf"}</p>
                            </div>
                          </div>
                        </div>

                        {/* Bodily Injury Photos */}
                        <XMultiImageUpload
                          label="Attach Bodily Injury Photographs"
                          name="bodilyInjuriesImageFiles"
                          onChange={bodilyInjuriesFilesOnChange}
                          initialFiles={bodilyInjuriesImageFiles}
                        />
                      </div>
                    </div>
                  </div>
                </RenderIf>

                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY].includes(formik.values.caseType)}>
                  {/* Regarding Notice Text */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <Field name="stateFactsNotice" placeholder="Write here..." label="State Any Facts Regarding Notice" component={TextArea} height={20.6} />
                    </div>
                  </div>
                </RenderIf>

                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY].includes(formik.values.caseType)}>
                  {/* Expert Safety Report */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleFormControlTextarea1" className="form-label">Attach Expert Safety Report (If Available)</label>
                      <div className="file-upload" >
                        <div className="text text-center" style={{ position: 'relative' }}>

                          {formik?.values?.expertSafetyReport?.length > 0 ? (<><i className='fa-solid fa-eye'
                            onClick={(e) => {
                              e.stopPropagation()
                              viewFileInNewTab(formik?.values?.expertSafetyReport[0])
                            }}
                          ></i><i className='fa-solid fa-trash ms-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              formik.setFieldValue('expertSafetyReport', [])
                            }}
                          ></i></>) : (<> <Field name="expertSafetyReport" component={FileUpload} multiplefileUpload={true} onFileSelected={(files) => {
                            formik.setFieldValue("expertSafetyReport", files?.file);
                          }} documentName='expertSafetyReport' accept="application/pdf" />
                            <i className="fa-solid fa-upload"></i></>)}

                          <p>{formik.values?.expertSafetyReport?.length >= 1 ? (formik.values?.expertSafetyReport?.length === 1 ? formik.values?.expertSafetyReport[0]?.name : 'Multiple Files Selected') : "Upload file type .pdf"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </RenderIf>



                <RenderIf shouldRender={[CASE_TYPE.TRIP_AND_FALL, CASE_TYPE.SLIP_AND_FALL, CASE_TYPE.PREMISE_LIABILITY, CASE_TYPE.DOG_BITE, CASE_TYPE.AUTO_ACCIDENT].includes(formik.values.caseType)}>
                  {/* Witness Statement File */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="exampleFormControlTextarea1" className="form-label">Attach Witness Statement (If Available)</label>
                      <div className="file-upload" >
                        <div className="text text-center" style={{ position: 'relative' }}>
                          {formik?.values?.witnessStatementFile?.length > 0 ? (<><i className='fa-solid fa-eye'
                            onClick={(e) => {
                              e.stopPropagation()
                              viewFileInNewTab(formik?.values?.witnessStatementFile[0])
                            }}
                          ></i><i className='fa-solid fa-trash ms-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              formik.setFieldValue('witnessStatementFile', [])
                            }}
                          ></i></>) : (<><Field name="witnessStatementFile" component={FileUpload} multiplefileUpload={true} onFileSelected={(files) => {
                            formik.setFieldValue("witnessStatementFile", files?.file);
                          }} documentName='witnessStatementFile' accept="application/pdf" /><i className="fa-solid fa-upload"></i></>)}
                          <p>{formik.values?.witnessStatementFile?.length >= 1 ? (formik.values?.witnessStatementFile?.length === 1 ? formik.values?.witnessStatementFile[0]?.name : 'Multiple Files Selected') : "Upload file type .pdf"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </RenderIf>


                <RenderIf shouldRender={formik.values.caseType === CASE_TYPE.AUTO_ACCIDENT}>
                  {/* Police Report File */}
                  <div className="col-md-6">
                    <div className="form-group mb-0">
                      <RenderIf shouldRender={!shouldRenderIncindentDescription}>
                        <label for="exampleFormControlTextarea1" className="form-label">Attach Police Report (If Available)</label>
                        <div style={{ marginLeft: '41.8rem', marginTop: '-32px' }}>
                          No Police Report
                          <button onClick={() => {
                            setShouldRenderIncidentDescription(true)
                            setPoliceReportUpload([])
                          }} style={{
                            marginLeft: '15px',
                            marginBottom: '5px', width: '100px', border: 'none', borderRadius: '20px', backgroundColor: '#18479A', color: 'white'
                          }}>Click here</button>
                        </div>
                        <div className="file-upload">
                          <div className="text text-center">
                            {policeReportUpload?.length > 0 ? (<><i className='fa-solid fa-eye'
                              onClick={(e) => {
                                e.stopPropagation()
                                viewFileInNewTab(policeReportUpload[0])
                              }}
                            ></i><i className='fa-solid fa-trash ms-2'
                              onClick={(e) => {
                                e.stopPropagation()
                                setPoliceReportUpload([])
                                formik.setFieldValue('policeReport', [])
                              }}
                            ></i></>) : (<><Field name="policeReport" component={FileUpload} multiplefileUpload={true} onFileSelected={onFileSelected} documentName='policeReport' accept="application/pdf" />
                              <i className="fa-solid fa-upload"></i></>)}


                            <p>{fileName ? fileName?.length > 1 ? fileName : 'Multiple Files Selected' : "Upload file type .pdf, .jpg, .png"}</p>
                          </div>
                        </div>
                      </RenderIf>
                      <div className="form-group">
                        <RenderIf shouldRender={shouldRenderIncindentDescription}>
                          <div className="form-group">
                            <span style={{ position: 'absolute', marginLeft: '43.4rem' }}>
                              Police Report
                              <button onClick={() => { setShouldRenderIncidentDescription(false) }} style={{
                                marginLeft: '15px',
                                width: '100px', border: 'none', borderRadius: '20px', backgroundColor: '#18479A', color: 'white'
                              }}>Click here</button>
                            </span>
                            <Field name="description" placeholder="Write here..." label="Describe Incident (if no police report)" component={TextArea} height={20.6} />
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <XMultiImageUpload
                      label="Attach Photographs of Accident Scene and Property Damage (If Available)"
                      name="accidentSceneFiles"
                      onChange={accidentSceneFilesOnChange}
                      initialFiles={accidentSceneFiles}
                    />
                  </div>
                </RenderIf>

                <RenderIf shouldRender={formik.values.caseType !== CASE_TYPE.PRODUCT_LIABILITY}>
                  <div className="col-md-6">
                    <XMultiImageUpload
                      label="Attach Bodily Injury Photographs"
                      name="bodilyInjuriesImageFiles"
                      onChange={bodilyInjuriesFilesOnChange}
                      initialFiles={bodilyInjuriesImageFiles}
                    />
                  </div>
                </RenderIf>
              </div>

            </div>
            <div className="btns text-center">
              <button className="btn-theme" type="submit">Next</button>
            </div>
          </div>
        </Form>
      )
      }
    </Formik >
  )

}

export default LiabilityAnalysisForm;