import * as type from '../type';

export function storeLiablity(liability) {
  return {
    type: type.STORED_LIABILTY,
    payload: liability,
  }
}
export function storeLiablityEstern(liability) {
  return {
    type: type.STORED_LIABILTY,
    payload: liability,
  }
}
export function storeInjuryAnalysis(InjuryData) {
  return {
    type: type.STORED_INJURY_ANALYSIS,
    payload: InjuryData,
  }
}
export function storeInjuryAnalysisEstern(InjuryData) {
  return {
    type: type.STORED_INJURY_ANALYSIS,
    payload: InjuryData,
  }
}
export function storeDamageAnalysisEstern(DamageData) {
  return {
    type: type.STORED_DAMAGE_ANALYSIS,
    payload: DamageData,
  }
}

export function preProcessedMedicalRecordsData(PreProcessMedicalRecords) {
  return {
    type: type.PRE_PROCCESSED_MEDICAL_DATA,
    payload: PreProcessMedicalRecords
  }
}