import { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import { Field } from 'formik';
import { FileUpload } from '../../../../components';
import './XMultiImageUpload.css';
import Constants from '../../../../Constants';
import useAxios from '../../../../hooks/useAxios';

const XMultiImageUpload = ({
    label,
    acceptedFileTypes = ".jpg,.jpeg,.png",
    name,
    initialFiles = [],
    onChange
}) => {
    const [filesData, setFilesData] = useState([]);
    const { postData } = useAxios()

    useEffect(() => {
        if (initialFiles.length > 0) {
            const initialFileObjects = initialFiles.map(v => ({
                id: Math.random().toString(36).substr(2, 9),
                url: v?.url,
                name: v?.name,
                isSelected: !!v?.isSelected,
                s3UrlPath: v?.s3UrlPath,
                file: v?.file,
            }));
            setFilesData(initialFileObjects);
        }
    }, [initialFiles]);

    const handleFiles = (files) => {
        const newFiles = Array.from(files.file).map(file => ({
            id: Math.random().toString(36).substr(2, 9),
            file: file,
            url: URL.createObjectURL(file),
            name: file.name,
            isSelected: false,
        }));

        // Filter out duplicates based on filename
        const uniqueNewFiles = newFiles.filter(newFile =>
            !filesData.some(existingFile => existingFile.name === newFile.name)
        );

        const updatedFiles = [...filesData, ...uniqueNewFiles];
        setFilesData(updatedFiles);
        onChange(updatedFiles);
    };

    const handleSelectFile = (id) => {
        const updatedFiles = filesData.map(fileData =>
            fileData.id === id
                ? { ...fileData, isSelected: !fileData.isSelected }
                : fileData
        );
        setFilesData(updatedFiles);
        onChange(updatedFiles);
    };

    const removeFile = (id) => {
        const updatedFiles = filesData.filter(fileData => fileData.id !== id);
        setFilesData(updatedFiles);
        onChange(updatedFiles);
    };

    const viewFileInNewTab = async (file) => {
        if (file instanceof File || file instanceof Blob) {
            window.open(file.url, '_blank');
        }
        if (file.s3UrlPath) {
            const response = await postData(Constants.ApiUrl.utils.getSignedUrl, {
                path: file.s3UrlPath
            })
            const signedUrl = response?.data?.signedUrl
            signedUrl && window.open(response?.data?.signedUrl, '_blank');
        }
    }

    return (
        <div className="form-group mb-0">
            <label for={`${label}-id`} className="form-label">{label}</label>
            <div className="file-upload">
                {filesData.length > 0 && (
                    <div className="image-grid">
                        {filesData.map((fileData) => (
                            <div key={fileData.id} className="image-item">
                                <div className="image-header">
                                    <button
                                        type="button"
                                        className="remove-button"
                                        onClick={() => removeFile(fileData.id)}
                                    >
                                        X
                                    </button>
                                </div>
                                <label>
                                    <div className="checkbox-container">
                                        <Checkbox
                                            color="success"
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            checked={fileData.isSelected}
                                            onChange={() => handleSelectFile(fileData.id)}
                                        />
                                        <p className="file-name">{fileData.name}</p>
                                    </div>
                                </label>
                                <div>
                                    {fileData.s3UrlPath ? (
                                        <div className='eyeIcon'>
                                            <i className='fa-solid fa-eye'
                                                onClick={(e) => {
                                                    viewFileInNewTab(fileData)
                                                }}
                                            ></i>
                                        </div>
                                    ) : (
                                        <img
                                            src={fileData.url}
                                            alt={fileData.name}
                                            className="preview-image"
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="upload-section">
                    <Field
                        name="files"
                        component={FileUpload}
                        accept={acceptedFileTypes}
                        multiplefileUpload={true}
                        onFileSelected={handleFiles}
                        documentName={name}
                    />
                    <i className="fa-solid fa-upload uploadIcon"></i>
                    {filesData.length === 0 && (
                        <p>Upload file type {acceptedFileTypes}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default XMultiImageUpload;