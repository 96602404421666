import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderIf } from '../../../../components';
import DamagesAnalysisForm from './DamagesAnalysisForm';
import InjuriesAnalysisForm from './InjuriesAnalysisForm';
import LiabilityAnalysisForm from './LiabilityAnalysisForm';
import Progressbar from './Progressbar';
import Constants from '../../../../Constants';
// import { Document, Page } from 'pdfjs-dist';
import { pdfjs } from 'react-pdf';
import useAxios from '../../../../hooks/useAxios';
import { preProcessedMedicalRecordsData, storeDamageAnalysisEstern, storeInjuryAnalysis, storeLiablity } from '../../../../redux/actions/liabilty';
import { useDispatch } from 'react-redux';
// import { PDFViewer, Text, View, Document, Page, StyleSheet } from '@react-pdf/renderer';
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// console.log(pdfjs.version)

const AddCaseForm = ({ addCases }) => {
  const { caseId } = useParams();
  const [formSteps, setFormSteps] = useState(0);
  const [formDataPayload, setFormDataPayload] = useState({});
  const [policeReport, setPoliceReport] = useState([]);
  const [accidentScenesPhotos, setAccidentScenesPhotos] = useState('')
  const [bodyInjuryFiles, setBodyInjuryFiles] = useState('')
  const [injuriesPhotos, setInjuriesPhotos] = useState('');
  const [medicalRecordsPdf, setMedicalRecordsPdf] = useState('')
  const [medicalBillsfile, setMedicalBillFile] = useState('')
  const [incomeDocumentPhotos, setIncomeDocumentPhotos] = useState('')
  const [isGeneratingPdf, setIsGeneratingPdf] = useState('');
  const [caseName, setCaseName] = useState('');
  const { getData: getCaseById, isLoading: getCaseByIdLoading } = useAxios()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let medicalBillPdf = [];


  useEffect(() => {
    const fetchCaseDetails = async () => {
      if (caseId) {
        try {
          const response = await getCaseById(`${Constants.ApiUrl.case.getCaseById}/${caseId}`)
          const caseDetails = response.data;
          dispatch(storeLiablity(caseDetails?.detailsInput?.liability));
          dispatch(storeInjuryAnalysis(caseDetails?.detailsInput?.injury));
          dispatch(storeDamageAnalysisEstern(caseDetails?.detailsInput?.damage?.values));
          dispatch(preProcessedMedicalRecordsData({
            medicalProcessedData: caseDetails.result?.medicalRecords?.flat(),
            preMedicalProcessedData: caseDetails.result?.preMedicalRecords?.flat(),
            preMedicalBillProccessedData: caseDetails.result?.medicalBillRecords,
          }));


        } catch (error) {
          console.error('Error fetching case details:', error);
        }
      }
    };

    fetchCaseDetails();
  }, [caseId]);



  const onPoliceReportUpload = (file) => {

    setPoliceReport(file);
    console.log("ploice", file)
  }

  const onAccidentSecnesUpload = (file) => {
    setAccidentScenesPhotos(file)
    console.log("accident", file)
  }

  const onInjuriesPhotosUpload = (file) => {
    setInjuriesPhotos(file)


    console.log("injury", file)
  }


  const onMedicalRecordsUpload = (file) => {

    setMedicalRecordsPdf(file)
    // for (var i = 0; i < file.length; i++) {
    //   medicalBillPdf.push(file[i])
    // }
    // console.log("medical", file)
  }


  const onMedicalBillUpload = (file) => {
    setMedicalBillFile(file)
    console.log("medicalBill", file)
  }

  const onIncomeDocmentUpload = (file) => {
    setIncomeDocumentPhotos(file);
    console.log("incomeDocument", file)
  }


  const incrementFormStep = () => {

    setFormSteps(formSteps + 1);
  }

  const Decrement = () => {

    setFormSteps(formSteps - 1)
  }

  const finalFormStepNumber = 2
  const onSubmitClick = (formData) => {
    formData = { ...formDataPayload, ...formData };
    setFormDataPayload(formData);
    if (formData.isDraftCase) {
      formData.caseId = caseId;
      addCases({ data: formData });
    }
    else {
      if (formSteps === finalFormStepNumber) {
        setFormSteps(0);
        processPromptInputContentOnServer(formData);
      }
      else {
        incrementFormStep();
      }
    }

  }

  const processPromptInputContentOnServer = (data) => {
    data.caseId = caseId

    setIsGeneratingPdf(true);

    const formsPayload = {
      data,
      // policeReport,
      // accidentScenesPhotos,
      // bodyInjuryFiles,
      // injuriesPhotos,
      // medicalRecordsPdf,
      // medicalBillsfile,
      // incomeDocumentPhotos
    }
    addCases(formsPayload)
    navigate('/cases', { state: { formsPayload } })
  }

  const fromStepValue = (value) => {
    setFormSteps(value)
    console.log(value)
  }

  return (
    <>
      <RenderIf shouldRender={!isGeneratingPdf} >
        <Progressbar formSteps={formSteps} fromStepValue={fromStepValue} />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 0 && !isGeneratingPdf}>
        <LiabilityAnalysisForm
          onSubmit={onSubmitClick}
          onPoliceReportUpload={onPoliceReportUpload}
          onAccidentSecnesUpload={onAccidentSecnesUpload}
          onBodyInjuryUpload={setBodyInjuryFiles}
          caseName={caseName} // Pass caseName and setCaseName to LiabilityAnalysisForm
          setCaseName={setCaseName}
        />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 1 && !isGeneratingPdf}>
        <InjuriesAnalysisForm
          onSubmit={onSubmitClick}
          stepDecrement={Decrement}
          onMedicalRecordsUpload={onMedicalRecordsUpload}
        />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 2 && !isGeneratingPdf}>
        <DamagesAnalysisForm
          onSubmit={onSubmitClick}
          stepDecrement={Decrement}
          onInjuriesPhotosUpload={onInjuriesPhotosUpload}
          onMedicalBillUpload={onMedicalBillUpload}
          onIncomeDocmentUpload={onIncomeDocmentUpload}
        />
      </RenderIf>

      <RenderIf shouldRender={isGeneratingPdf}>
      </RenderIf>



    </>
  )



}


export default AddCaseForm;